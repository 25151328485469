import React from "react";

const Thank = () => {
  return (
    <div>
      <h1>thank</h1>
    </div>
  );
};

export default Thank;
